<template>
  <div class="container">
    <div class="container-con">
      <!-- Header S -->
      <Head
        :backIsShow="true"
      />
      <!-- Header E -->
      <!-- navBar S -->
      <Nav/>
      <!-- navBar E -->
      <!-- search button S -->
      <section class="m-dxby-search-btn">
        <router-link to="/search">输入产品编号，如：5012</router-link>
      </section>
      <!-- search button E -->
      <!-- nav S -->
      <section :style="{height: addFixeds === true ? '70px' : 'auto'}">
        <section :class="addFixeds === true ? 'addFixed' : ''">
          <nav class="m-dxby-prd-nav">
            <div class="m-dxby-prd-nav-wrap">
              <ul>
                <li
                  v-for="(item, key) in navData"
                  :key="key"
                  @click="changeActive(key, item.id)"
                  :class="key === activeIndex ? 'active' : ''"
                >{{item.name}}</li>
              </ul>
            </div>
          </nav>
          <div class="m-dxby-nav-shadow"></div>
        </section>
      </section>
      <!-- nav E -->
      <!-- list S -->
      <section class="m-dxby-prd-list">
        <ul>
          <li
            v-for="(item, key) in getlistData"
            :key="key"
            @click="toDetails(item)"
          >
            <div class="m-dxby-prd-list-item">
              <div class="m-dxby-prd-list-item-img">
                <img :src="item.mainImg" :alt="item.name">
              </div>
              <div class="m-dxby-prd-list-item-text">
                <h3>{{item.sn}}</h3>
                <span>{{item.name}}</span>
              </div>
            </div>
          </li>
        </ul>
      </section>
      <!-- list E -->
      <div class="m-dxby-loading">{{loadingText}}</div>
    </div>
    <!-- 小程序商城入口 S -->
    <section
      class="m-dxby-ShopEnter"
      @click="showPopup"
    >
      <ShopEnter/>
    </section>
    <!-- 小程序商城入口 E -->
    <!-- 返回顶部 S -->
    <BackTop ref="isBackTop"/>
    <!-- 返回顶部 E -->
    <!-- 商城列表 S -->
    <Popup
      v-model="popupShow"
      position="bottom"
      :closeable="true"
    >
      <div class="m-dxby-qrcode-popupShow">
        <ul>
          <li
            v-for="(item, key) in qrCodeData"
            :key="key"
          >
            <img :src="item.qrcodeImg" :alt="item.name">
            <span>{{item.name}}</span>
            <span>{{item.des}}</span>
          </li>
        </ul>
      </div>
    </Popup>
    <!-- 商城列表 E -->
    <Foot/>
  </div>
</template>

<script>
import axios from 'axios'
import Head from '@/components/Head'
import Nav from '@/components/Nav'
import Foot from '@/components/Foot'
import metaLable from '@/utils/metaLable'
import BackTop from '@/components/BackTop2'
import ShopEnter from '@/components/ShopEnter'
import { Popup } from 'vant'
import { hostApi } from '@/utils/requestApi'
import { qrCodeData } from '@/utils/constantManage'
import { OtherCommonFn } from '@/assets/js/common'

export default {
  name: 'product',
  metaInfo: {
    title: '大新布业 - 商品中心',
    meta: metaLable
  },
  data () {
    return {
      addFixeds: false,
      popupShow: false,
      total: 0,
      getlistData: [],
      activeIndex: 0,
      navData: [],
      loading: false,
      finished: false,
      qrCodeData,
      pageNum: 1,
      pageSize: 6,
      loadingText: '加载中...'
    }
  },
  components: {
    Head,
    Foot,
    Nav,
    BackTop,
    ShopEnter,
    Popup
  },
  methods: {
    // 获取到导航栏数据
    async getNavData () {
      const res = await axios.get(`${hostApi}/goods/class/list`)
      const { data } = res
      this.navData = data
    },
    // 选项卡 - 点击获取到对应的数据列表
    changeActive (key, id) {
      this.activeIndex = key
      this.getlistData = []
      this.pageNum = 1
      this.getSelectMenuData(id)
      new OtherCommonFn().setLocalStorage('pId', id)
    },
    // 获取到对应的数据列表
    async getSelectMenuData (id = '1356848605293449218') {
      const pData = {
        id,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      const res = await axios.post(`${hostApi}/goods/spu/list/cls`, pData)
      const { list, total } = res.data
      this.getlistData.push(...list)
      this.total = Number(total)
      // console.log(this.getlistData)
    },
    toDetails (item) {
      this.$router.push({
        path: '/productDetails',
        query: {
          id: item.id
        }
      })
    },
    showPopup () {
      this.popupShow = true
    },
    // 浏览器滚动时元素距离顶部多少是显示和隐藏
    onscrollFn () {
      document.addEventListener('scroll', () => {
        let _topDistance = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
        _topDistance > 203 ? this.addFixeds = true : this.addFixeds = false
        _topDistance > 200 ? this.$refs.isBackTop.changeBackTopIsStatusFn(true) : this.$refs.isBackTop.changeBackTopIsStatusFn(false)
      })
    },
    getOnscrollFresh () {
      window.addEventListener('scroll', () => {
        let pId = new OtherCommonFn().getStorage('pId')
        let _sceollVal = document.documentElement.scrollHeight - document.documentElement.scrollTop - document.documentElement.clientHeight
        if (_sceollVal >= 120) {
          // 写后台加载数据的函数
          if (this.getlistData.length >= this.total) {
            this.loadingText = '- 到底了-'
            return
          }
          this.pageNum++
          this.getSelectMenuData(!pId ? '1356848605293449218' : pId)
        }
      })
    }
  },
  created () {
    new OtherCommonFn().removeStorage('pId')
    this.getOnscrollFresh()
  },
  mounted () {
    this.getNavData()
    new OtherCommonFn().removeStorage('pId')
    // here is from ohter page to product page
    const { fromTo } = this.$route.query
    if (fromTo) {
      let pId = new OtherCommonFn().getStorage('fromSearchPId')
      let ohterPageMenuIndex = new OtherCommonFn().getStorage('prdKey')
      this.activeIndex = Number(ohterPageMenuIndex)
      this.getSelectMenuData(pId)
    } else {
      new OtherCommonFn().removeStorage('fromSearchPId')
      new OtherCommonFn().removeStorage('prdKey')
      this.getSelectMenuData()
    }
    this.onscrollFn()
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.addFixed {
  .w(100%);
  .bg(rgba(255, 255, 255, .9));
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
// 加载中
.m-dxby-loading {
  text-align: center;
  color: #333;
  .fsize(14px);
  padding: 10px 0;
  margin-bottom: 10px;
}
.container-cons {
  height: 100%;
}
.m-dxby-prd-nav {
  margin: 0 10px;
  .m-dxby-prd-nav-wrap {
    min-width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    ul {
      min-width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      padding: 16px 0;
      li {
        margin-right: 20px;
        padding: 8px 15px;
        font-size: 15px;
        box-shadow: 0px 2px 5px #666;
        margin-right: 12px;
        background-color: #fff;
        color: #999;
        border-radius: 5px;
        white-space: nowrap;
      }
      .active {
        color: #fff;
        background-color: #015462;
      }
    }
  }
}
.m-dxby-nav-shadow {
  .h(1px);
  box-shadow: 0 3px 3px #000;
  margin-bottom: 25px;
}
// list
.m-dxby-prd-list {
  margin: 15px 10px;
  overflow: hidden;
  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 33.3%;
      overflow: hidden;
      .m-dxby-prd-list-item {
        width: 92%;
        overflow: hidden;
        margin: 0 auto;
        .m-dxby-prd-list-item-img {
          width: 100%;
          height: 100px;
          overflow: hidden;
          border-radius: 5px;
          img {
            width: 100%;
            border-radius: 5px;
          }
        }
      }
      .m-dxby-prd-list-item-text {
        padding: 13px 0 28px;
        h3 {
          font-size: 16px;
          color: #000;
          display: block;
          .otx (1);
          text-align: center;
          margin-bottom: 5px;
        }
        span {
          max-height: 40px;
          font-size: 14px;
          color: #666;
          display: block;
          .otx (2);
          .lht(20px);
          .otx (2);
          text-align: center;
        }
      }
    }
  }
}
// 商城二维码入口
.m-dxby-ShopEnter {
  position: fixed;
  right: 5px;
  bottom: 160px;
}
// 商城二维码
.m-dxby-qrcode-popupShow {
  padding: 20px 0 30px;
  margin-top: 35px;
  ul {
    display: flex;
    justify-content: space-between;
    li {
      flex: 1;
      text-align: center;
      img {
        .w(80%);
      }
      span {
        display: block;
        text-align: center;
        .otx (1);
        .fsize(10);
      }
      span:nth-child(2) {
        font-weight: bold;
      }
    }
  }
}
// 搜索按钮
.m-dxby-search-btn {
  a {
    .h(40px);
    .lht(40px);
    .fsize(14px);
    margin: 15px 10px 0;
    color: rgba(102, 102, 102, 0.6);
    display: block;
    border: 1px solid #ccc;
    border-radius: 30px;
    text-indent: 20px;
    background: url(~@/assets/images/prodction/sousuo.png) no-repeat 97% center / 28px;
  }
}
</style>
