<template>
  <div class="shop-enter">
    <van-image
      width="60"
      :src="wxEnterIcon"
    />
  </div>
</template>

<script>
import { WEISHANGCHEN } from '@/utils/imageManage'
import { Image as VanImage } from 'vant'

export default {
  name: '',
  data () {
    return {
      wxEnterIcon: WEISHANGCHEN
    }
  },
  components: {
    VanImage
  }
}
</script>

<style lang="less">
.shop-enter {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  // border: 1px solid #333;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
